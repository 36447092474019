import axios from "axios"
import authAPI from "../pages/login"

// export const rootPath = "https://apis-siigap.underdev.team"
export const rootPath       = !process.env.NODE_ENV || process.env.NODE_ENV === 'production' ? window._env_.REACT_APP_API_GATEWAY : process.env.REACT_APP_BASE_ENDPOINT
export const sadapPath      = !process.env.NODE_ENV || process.env.NODE_ENV === 'production' ? window._env_.REACT_APP_SADAP : process.env.REACT_APP_SADAP_ENDPOINT

var userData                = JSON.parse(localStorage.getItem("userData"));

const configHeaders =
    !process.env.NODE_ENV || process.env.NODE_ENV === "production"
        ? {
            "Content-Type"                  : "application/json",
            "Access-Control-Allow-Origin"   : window._env_.REACT_APP_CORS_ORIGIN,
            "Cache-Control"                 : "no-cache",
            "Pragma"                        : "no-cache",
            "Expires"                       : "0"
        }
        : {
            "Content-Type"                  : "application/json",
            "Authorization"                 : "Bearer " + localStorage.getItem("token"),
            "X-AUTH-UUID"                   : userData?.uuid,
            "Access-Control-Allow-Origin"   : ".rlidev.pro, localhost:3000, .mapbox.com, .test-siaccinfo.id, .sysdev.id, .siigapinfo.id", 
            "Cache-Control"                 : "no-cache",
            "Pragma"                        : "no-cache",
            "Expires"                       : "0"

        }

const handleRedirect = async() => {
    await authAPI.logOutUser()
    localStorage.clear();
    window.location.href ='/login'
}

export const Get = (path, param) => {
    const promise = new Promise((resolve, reject) => {
        axios({
            method  : "get",
            url     : `${rootPath}/${path}`,
            params  : param,
            headers : configHeaders
        })
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                if (err.response) {
                    if(err.response.status === 410){
                        handleRedirect()
                    }else{
                        reject(err.response)
                    }
                } else if (err.request) {
                    reject(err.request)
                } else {
                    reject(err)
                }
            })
    })
    return promise
}

export const GetImage = (path) => {
    const promise = new Promise((resolve, reject) => {
        axios({
            method  : "get",
            url     : `${path}`,
            responseType: 'arraybuffer',
            headers : configHeaders
        })
            .then((res) => {
                console.log(res)
            })
            
    })
    return promise
}

export const GetExternalSadap = (path, param) => {
    const promise = new Promise((resolve, reject) => {
        axios({
            method: "get",
            url: `${sadapPath}/${path}`,
            headers: configHeaders,
            params: param,
            withCredentials: false
        })
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response)
                } else if (err.request) {
                    reject(err.request)
                } else {
                    reject(err)
                }
            })
    })
    return promise
}

export const PostExternalSadap = (path, formData) => {
    const promise = new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: `${sadapPath}/${path}`,
            data: formData,
            headers: configHeaders
        })
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response)
                } else if (err.request) {
                    reject(err.request)
                } else {
                    reject(err)
                }
            })
    })
    return promise
}
export const GetMarker = (path, param) => {
    const promise = new Promise((resolve, reject) => {
        axios({
            method: "get",
            url: `${path}`,
            headers: configHeaders,
            params: param,
            withCredentials: false
        })
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response)
                } else if (err.request) {
                    reject(err.request)
                } else {
                    reject(err)
                }
            })
    })
    return promise
}

export const GetDownload = (path, fileName) => {
    const promise = new Promise((resolve, reject) => {
        axios({
            method: "get",
            url: `${path}`,
            headers: configHeaders,
            responseType: "blob",
            withCredentials: false
        })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement("a")

                link.href = url

                link.setAttribute("download", fileName)
                document.body.appendChild(link)

                const result = link.click()

                resolve(result)
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response)
                } else if (err.request) {
                    reject(err.request)
                } else {
                    reject(err)
                }
            })
    })
    return promise
}

export const Post = (path, formData) => {
    const promise = new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: `${rootPath}/${path}`,
            data: formData,
            headers: configHeaders
        })
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response)
                } else if (err.request) {
                    reject(err.request)
                } else {
                    reject(err)
                }
            })
    })
    return promise
}

export const PostDownload = (path, formData, fileName) => {
    const promise = new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: `${rootPath}/${path}`,
            data: formData,
            responseType: "blob",
            headers: configHeaders
        })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement("a")

                link.href = url

                link.setAttribute("download", fileName)
                document.body.appendChild(link)

                const result = link.click()

                resolve(result)
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response)
                } else if (err.request) {
                    reject(err.request)
                } else {
                    reject(err)
                }
            })
    })

    return promise
}

export const PostUpload = (path, formData, onUploadProgress) => {
    const promise = new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: `${rootPath}/${path}`,
            data: formData,
            headers: configHeaders,
            onUploadProgress
        })
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response)
                } else if (err.request) {
                    reject(err.request)
                } else {
                    reject(err)
                }
            })
    })

    return promise
}

export const PostCustom = (path, formData) => {
    const promise = new Promise((resolve, reject) => {
        axios({
            method          : "post",
            url             : `${path}`,
            data            : formData,
            headers         : configHeaders,
            withCredentials : true
        })
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response)
                } else if (err.request) {
                    reject(err.request)
                } else {
                    reject(err)
                }
            })
    })
    return promise
}

export const Put = (path, formData) => {
    const promise = new Promise((resolve, reject) => {
        axios({
            method: "put",
            url: `${rootPath}/${path}`,
            data: formData,
            headers: configHeaders
        })
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response)
                } else if (err.request) {
                    reject(err.request)
                } else {
                    reject(err)
                }
            })
    })

    return promise
}

export const Delete = (path, formData) => {
    const promise = new Promise((resolve, reject) => {
        axios({
            method: "delete",
            url: `${rootPath}/${path}`,
            data: formData,
            headers: configHeaders
        })
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response)
                } else if (err.request) {
                    reject(err.request)
                } else {
                    reject(err)
                }
            })
    })

    return promise
}
