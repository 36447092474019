import {
    Get,
    Post,
    Delete,
    Put,
    GetMarker,
    PostUpload
}                           from "../../core/request"

import { employeeURL }      from "../../core/url"

const getEmployee       = (data) => Get(`${employeeURL.employeePrefix}/${employeeURL.employee}`, data)

const getEmployeeDetail = (data) => Post(`${employeeURL.employeePrefix}/${employeeURL.employee}/by-uuid`, data)

const getEmployeeLimit  = (data) => Get(`${employeeURL.employeePrefix}/${employeeURL.employee}?limit=5000`, data)

const getEmployeeList   = (data) =>
    Get(
        `${employeeURL.employeePrefix}/${employeeURL.employee}/${employeeURL.employeeList}`,
        data
    )

const updateEmployee = (data) =>
    Put(
        `${employeeURL.employeePrefix}/${employeeURL.employee}/${employeeURL.updateEmployee}`,
        data
    )

const createEmployee = (data) =>
    Post(
        `${employeeURL.employeePrefix}/${employeeURL.employee}/${employeeURL.createEmployee}`,
        data
    )

const filterEmployee = (data) =>
    Post(
        `${employeeURL.employeePrefix}/${employeeURL.employee}/${employeeURL.filterEmployee}`,
        data
    )

const uploadPhotoEmployee = (data) =>
    PostUpload(
        `${employeeURL.employeePrefix}/${employeeURL.employee}/${employeeURL.uploadPhoto}`,
        data
    )

const updatePhotoEmployee = (data) =>
    Post(
        `${employeeURL.employeePrefix}/${employeeURL.employee}/${employeeURL.updatePhoto}`,
        data
    )

const deleteEmployee = (data) =>
    Delete(
        `${employeeURL.employeePrefix}/${employeeURL.employee}/${employeeURL.deleteEmployee}`,
        data
    )

const updateEmpoleeStatus = (data) =>
    Put(
        `${employeeURL.employeePrefix}/${employeeURL.employee}/update?status`,
        data
    )

const getWorkunitLevel      = (data) => Get(`${employeeURL.employeePrefix}/${employeeURL.workunitLevel}`, data)

const getWorkUnit           = (data) => Get(`${employeeURL.employeePrefix}/${employeeURL.workunit}`, data)

const getWorkUnitList       = (data) => Get(`${employeeURL.employeePrefix}/${employeeURL.workunit}/list`, data)

const getSector             = (data) => Get(`${employeeURL.employeePrefix}/${employeeURL.sector}`, data)

const getPosition           = (data) => Get(`${employeeURL.employeePrefix}/${employeeURL.position}`, data)

const getPositionList       = (data) => Get(`${employeeURL.employeePrefix}/${employeeURL.position}/list`, data)

const authAPI = {
    getEmployee,
    getEmployeeDetail,
    getEmployeeLimit,
    getEmployeeList,
    updateEmployee,
    createEmployee,
    deleteEmployee,
    getWorkunitLevel,
    getWorkUnit,
    getWorkUnitList,
    getSector,
    getPosition,
    getPositionList,
    uploadPhotoEmployee,
    updatePhotoEmployee,
    filterEmployee,
    updateEmpoleeStatus
}

export default authAPI
